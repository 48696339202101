<template>
    <section id="about-me" >        
        <div class="py-12"></div>          
        <v-container fluid justify-center align-center>
        <v-Card ma-5 elevatition8 id="vabout">
            <v-row justify-center >                
                <v-col cols="12" md="6" >
                        <v-container justify-center align-center text-center >
                            <v-row >
                            <v-col >
                                
                                <svg  viewBox="-110 0 310 107" version="1.1" id="svg5" inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)" sodipodi:docname="logo.svg" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
                                            <path d="M 13.473874,8.3807666 V 98.464416 L 64.387702,83.166566 48.270786,53.588435 64.38627,23.677419 Z" fill="#95a6c8" id="path21" sodipodi:nodetypes="cccccc" style="stroke-width:1.64457"/>
                                            <path d="M 44.207534,57.216627 95.26457,8.3804266 H 65.505434 L 19.983942,50.223699 Z" fill="#000000" id="path23" sodipodi:nodetypes="ccccc" style="stroke-width:1.64457"/>
                                            <path d="M 19.983942,50.223699 65.505434,98.463736 H 95.26457 L 44.207534,49.599659 Z" fill="#000000" id="path25" sodipodi:nodetypes="ccccc" style="stroke-width:1.64457"/>
                                </svg>  
                                <v-responsive class="mx-auto mb-8" width="240" >
                                    <v-divider class="mb-2"></v-divider>
                                    <v-divider></v-divider>
                                </v-responsive>
                                <h2 class="display-2 black--text font-weight-bold mb-3">ACERCA DE KREADORES</h2>
                            </v-col>                          
                            </v-row>
                        </v-container>
                        <v-container class="text-center black--text" justify-center align-center >
                            <v-row>
                                <v-col>
                                    <b>Empresa 100%  Mexicana de Servicios y Soluciones Informáticas</b>                                    
                                        <v-responsive class="mx-auto title font-weight-light mb-8" >
                                            <b>Kreadores</b> es una empresa dedicada a proporcionar servicios de  gestion de tecnologías de información,	
                                            a gran escala, con una amplia experiencia en el mercado.
                                            Actualmente la empresa esta conformada por los mejores profesionales de la industria, 
                                            abarcando desde expertos en ingeniera de software, arquitectura de servicios y desarrollo de software, hasta expertos en tecnologías de información.
                                        </v-responsive>
                                        
                                    <br>
                                    <br>
                                    <v-divider></v-divider>
                                    <v-divider></v-divider>
                                    <br>
                                    <br>
                                    <v-btn color="black" @click="$vuetify.goTo('#contact-me')"  large >
                                        <span class="white--text text--darken-1 font-weight-bold">
                                        Contacto
                                        </span>
                                    </v-btn>
                                </v-col>                    
                            </v-row>
                        </v-container>
                  
                </v-col>
                <v-col cols="12" md="6">
                        <v-container justify-center align-center >
                            <v-row>
                            <v-col>
                                <v-img  src="/cloud.webp"></v-img>                            
                            </v-col>
                            <v-col class="white--text text-center" cols="12" tag="h1">                
                                <v-btn  class="align-self-end" fab outlined @click="$vuetify.goTo('#header')" >
                                    <v-icon>mdi-chevron-double-up</v-icon>
                                </v-btn>                            
                                <v-btn class="align-self-end" fab outlined @click="$vuetify.goTo('#features')" >
                                    <v-icon>mdi-chevron-double-down</v-icon>
                                </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                        <v-responsive class="mx-auto dark--text title font-weight-light mb-8" >
                            <h2>Kreadores</h2> 
                            <b>
                                Tiene sus oficinas en el estado de Hidalgo y en la ciudad de San Juan del Rio Queretaro. 
                                Cuenta con una gran infraestructura tecnológica, personal con experiencia amplia en el mercado.        
                            </b>
                        </v-responsive>
                </v-col>
            </v-row>
        </v-Card>
        </v-container>
        <div class="py-12"></div>
      </section>
</template>

<script>
export default {
    
}
</script>

<style scoped>
    #vabout {
        /*
        background-image: url("/cloud.webp");
        background-color: rgba(233, 17, 17, 0.5);
        background-color: rgba(229, 57, 53, .9);
        background-image: url('../assets/layered-waves-haikei.png');
        */
        background-image: url('../assets/circle-scatter-haikei.svg');
        background-repeat: no-repeat;
        background-size: cover;
        
    }
</style>